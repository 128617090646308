body {
  margin: 0;
  background-color: bisque;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.searchBox{
  font-size: 16px;
}
.optionContainer > .option{
  font-size: 16px;
  color: black;
}

.chip{
  border-radius: 0px !important;
  border: 1px solid gray;
  background-color: transparent !important;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px !important;
  margin-right: 0px !important;
  line-height: 35px !important;
}
.col > div > .form-label{
  font-size: 18px;
}
