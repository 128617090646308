.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #2e2c2c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}
.App {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}
.App .container {
  padding-top: 50px;
  text-align: center;
  max-width: 90%;
}
.form-label{
  font-size: 14px !important;
}
.Header {
  top: 0; /* Stick it to the top */
  max-height: 70px;
  width: 100vw;
  grid-template-areas: "logo nav";
  background-color: #282c34;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.Nav {
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  justify-items: center;
  min-height: 6vh;
}
.Nav a {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
}
.Nav a:hover {
  transform: scale(1.1);
}
.Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

